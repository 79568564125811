<template>
  <main class="container">
    <div class="row justify-content-center">
      <div v-if="pending" class="text-center"><img src="/img/loading.gif" alt="загрузка"></div>

      <b-form v-else @submit.prevent="onSubmit" class="col-10">
        <p class="h3 mb-1">Создать хабовую страницу</p>
        <hr>

        <b-form-group
          label="Название страницы (не title):"
          label-for="name"
        >
          <b-form-input
            id="name"
            v-model="page.name"
            type="text"
            required
          />
        </b-form-group>

        <b-form-group
          label="Идентификатор страницы:"
          label-for="title"
        >
          <b-form-input
            id="alias"
            v-model="page.alias"
            type="text"
            required
          />
        </b-form-group>

        <b-button type="submit" variant="primary">Сохранить</b-button>
      </b-form>
    </div>
  </main>
</template>

<script>
export default {
  name: 'hubPagesCreate',
  metaInfo: {
    title: 'Создать хабовую страницу',
  },
  data() {
    return {
      page: {
        alias: '',
        name: '',
      },
      pending: false,
      breadcrumb: [
        {
          html: '<i class="fa fa-home" aria-hidden="true"></i>',
          to: { name: 'profile' }
        },
        {
          text: 'Хабовые страницы',
          to: { name: 'hubPages' }
        },
        {
          text: 'Создать хабовую страницу'
        }
      ]
    }
  },
  mounted: function() {
    this.$emit('breadcrumb', this.breadcrumb)
  },
  methods: {
    onSubmit() {
      this.pending = true

      this.$http.post('/akbiz/hubs/create', {page: this.page})
        .then((response) => {
          if (response?.data?.success) {
            this.$store.commit('addToast', {message: 'Страница добавлена'});
            this.$router.push(response?.data?.link)
          } else {
            this.$store.commit('addToast', {
              message: 'Ошибка добавления страницы. Возможно данный alias уже занят',
              type: 'error',
              title: 'Ошибка'
            });
          }
        })
        .finally(() => {
          this.pending = false
        })
    }
  }
}
</script>